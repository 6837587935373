@import "../settings";

@media print {
    a:after {
        content: '';
    }

    a[href]:after {
        content: none !important;
    }
}

.header {
    z-index: 10;

    .show-menu & {
        max-height: inherit;
    }

    @include breakpoint(small only) {
        max-height: rem-calc(10);
    }
}

.header__logo {
    display: block;
    margin: rem-calc(10 0);

    @include breakpoint(small only) {
        width: 75%;
    }

    @media print {
        margin: rem-calc(10 0);
    }
}

.header__logo-img {
    /*height: 150px;*/
    /*width: rem-calc(126);*/

    /*@include breakpoint(medium) {
        width: rem-calc(200);
    }

    @include breakpoint(large) {
        width: rem-calc(242);
    }*/
}

.header__nav-link {
    color: $secondary-color;

    @include breakpoint(medium only) {
        padding-right: 10px !important;
        padding-left: 10px !important;
        margin: 0;
    }

    @include breakpoint(large) {
        margin: rem-calc(0 20);
    }

    @include breakpoint(xlarge) {
        margin: rem-calc(0 35);
    }

    li:last-child > & {
        padding-right: 0 !important;
        margin-right: 0;
    }
}

.header__menu-btn {
    font-size: rem-calc(32);
    line-height: 1;
    position: absolute;
    right: rem-calc(30);
    top: rem-calc(15);
    font-family: $header-font-family;
    font-weight: $global-weight-bold;
    color: $medium-gray;

    > span {
        display: inline-block;
        width: rem-calc(30);
        height: rem-calc(30);
    }

    @include breakpoint(medium) {
        display: none;
    }

    &:hover {
        color: $black;
    }

    .header__menu-cross {
        display: none;
    }

    .show-menu & {

        .header__menu-cross {
            display: inline-block;
        }

        .header__menu-bars {
            display: none;
        }
    }
}

// foundation wrapper
.is-drilldown {
    pointer-events: none;

    .show-menu & {
        pointer-events: auto;
    }
}

.header__drill {
    width: 100%;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    top: rem-calc(110);
    right: 0;
    bottom: 0;
    background: $white;
    color: $secondary-color;
    min-height: 100%;
    transform: translate3d(100%,0,0);
    transition: transform .35s cubic-bezier(0.74, 0.04, 0.24, 0.92);
    padding-top: rem-calc(10);

    @include breakpoint(medium) {
        display: none;
    }

    a {
        color: $secondary-color;
        margin: rem-calc(0 15);
        padding: rem-calc(20 0) !important;
        border-bottom: 1px solid $medium-gray;
        text-align: left;

        &:hover {
            color: $primary-color;
        }
    }

    .show-menu & {
        transform: translate3d(0,0,0);
    }
}


.is-drilldown-submenu-parent > a:after,
.js-drilldown-back > a:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(/public/imgs/arrow-right.svg) no-repeat center center;
    background-size: 20px 20px;
    float: right;
    border: none;
}

.js-drilldown-back > a:before {
    transform: rotate(180deg);
    float: left;
}
