@import "../settings";

.submenu1 {
    text-align: left;
    border-top: none;

    li a {
        padding-top: .8em;
        padding-bottom: .8em;
    }
}

.menu {
    @media print {
        display:none;
    }
}

.menutop {
    font-family: 'Lato regular';
    font-size: rem-calc(22);
    color: #002330;
    /*&:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(/public/imgs/arrow-right.svg) no-repeat center center;
    background-size: 20px 20px;
    float: right;
    border: none;
}*/
    hover {
        color: #388c99;
    }
}
