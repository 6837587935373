@import "../settings";


.profile { 
    @include module-padding();
}

.profile__list {
    margin-top: $global-module-padding;
}

.profile__item{
    margin-bottom: rem-calc(30);
}

.profile__content {

    @include breakpoint(medium only) {
        margin-top: rem-calc(30);
    }
}

.profile__img {
    width: 100%;
}

.profile__text {
    margin-top: rem-calc(5);
    padding: rem-calc(15 20);
    color: $secondary-color;
    min-height: 120px;
    background-color: $light-gray;

    a {
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }
}

.profile__social-link {
    color: $light-gray !important;
    display: inline-block;
    width: rem-calc(28);
    height: rem-calc(28);
    border-radius: rem-calc(14);
    background-color: $primary-color;
    line-height: rem-calc(26);
    text-align: center;
    font-size: .85em;
    margin-top: rem-calc(10);

    &:hover {
        color: $white;
    }
}