.quickentrance {
    @include module-padding();
}

.quickentrance__link-wrapper {
    display: block;
    position: relative;
    padding: rem-calc(30 30 15);
    margin-bottom: rem-calc(30);
    border: 4px solid $light-gray;
    text-align: center;
    color: $secondary-color;
    box-shadow:  0 0 0 rgba(0,0,0,0);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    @include breakpoint(medium){
        height: rem-calc(325);
        padding: 0;
    }

    &:hover {
        border-color: $primary-color;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        color: $secondary-color;

        span {
            border-color:$primary-color;
            color:$primary-color;
        }
    }
}

.quickentrance__text {
    @include breakpoint(medium){
        @include vertical-center;
        width: 100%;
    }
}