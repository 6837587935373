﻿@import "../settings";

$orangebtn: #f48f3c;
$radiusrounding: 20px;

.topbutton {
    border-radius: $radiusrounding;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    @media print {
        display: none;
    }
}

.newletterbtnPicked {
    border-radius: $radiusrounding;
    text-align: center;
    color: $black;
    background-color: $white;
    background-color: $orangebtn;
    color: $white;
}

.newstypebtn {
    border-radius: $radiusrounding;
    text-align: center;
    color: $black;
    background-color: $white;

    &:hover {
        background-color: $orangebtn;
        color: $white;
    }
}

.newletterbtn {
    background-color: $orangebtn;
    max-width: 250px;
    width: 100%;
}
.topwhite {
    color: $black;
    background-color: $white;

    &:hover {
        color: $black;
        background-color: $white;
    }
}
.printbtn {
    display:inline-block;
    /*background: url('../../public/imgs/printer.svg');
    background-size: 30px 30px;*/
    height: 20px;
    width: 20px;
    @media print {
        display: none;
    }
}