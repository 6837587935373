@import "../settings";

h1 {
    font-weight: $global-weight-black;
}

h2 {
    margin-bottom: 1rem;
}

h4 {
    font-weight: $global-weight-bold;
}