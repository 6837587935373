@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../settings";

.carousel {
    picture {
        width: 100%;
        height: rem-calc(530);
        background-size: cover;
        display: block;
    }

    @media print {
        display: none;
    }
}
.slick-next, .slick-prev {
    position: absolute;
    top: 50%;
    right: 25px;
    margin-top: -25px;
    cursor: pointer;
    width: 50px;
    height: 60px;
    border: none;
    z-index: 5;
    
    @include breakpoint(small only){
        display: none !important;
    }

}

.slick-next {
    background: url('../../public/imgs/heroarrowright.png') no-repeat;
    
}
.slick-prev {
    background: url('../../public/imgs/heroarrowleft.png') no-repeat;
    right: auto;
    left: 25px;

    @media (max-width: 1440px){
        display: none !important;
    }
}
.carousel__item {
    position: relative;
    overflow: hidden;
    height: rem-calc(350);
    display: none;
    background-size: cover;
    background-position: center center;

    @include breakpoint(medium) {
        height: rem-calc(530);
    }

    &:first-child {
        display: block;
    }
}
.carousel__rowtext {
    /*width: $global-width;*/
}
.carousel__text {
    //padding-bottom: rem-calc(18);
    color: #ffffff;
    z-index: 1;
    font-family: 'Lato regular', sans-serif;
    width: 100%;
    @include vertical-center;

    @include breakpoint(medium) {
        //max-width: 85%;
        padding-bottom: rem-calc(30);
    }

    @include breakpoint(large) {
        //max-width:75%;
    }
}
.carousel__image {
    position: absolute;
    max-width: none;
    @include horizontal-center;
}
.carousel__header {
    padding: rem-calc(5 5);
    font-family: 'Georgia regular', 'Times New Roman', Times, serif;
    font-size: rem-calc(30);
    line-height: 1.2;
    color: #ffffff;

    @include breakpoint(medium){
        font-size: rem-calc(52);
    }


}
.slick-dots {
    position: relative;
    height: rem-calc(30);
    margin: rem-calc(-30 0 0);
    text-align: center;

    li {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $light-gray;
        border-radius: 5px;
        margin: rem-calc(5);

        &.slick-active {
            background: $primary-color;
        }

        button {
            display: none;
        }
    }
}