@import "../settings";
.footerphone {
    font-family: $header-font-family;
    font-size: rem-calc(20);
}
.stockImageTxt {
    &LeftTxt {
        font-family: Lato, sans-serif;
        font-weight: bold;
        font-size: rem-calc(16);
        color: #cccccc;
    }

    &RightTxt {
        font-family: Lato, sans-serif;
        font-weight: bold;
        font-size: rem-calc(16);
        color: #8e9698;
        text-align: right;
    }
}

.footer {
    color: $white;

    &.sec1 {
        height: 390px;
        background-image: url(/public/imgs/footer.jpg);
        background-size: cover;
        padding-top: 50px;

        @media print {
            display: none;
        }

        .header {
            font-family: $header-font-family;
            font-size: rem-calc(28);
            font-weight: 400;
            color: #efefef;
        }

        .stockheader {
            opacity: 0.8;
            font-family: $header-font-family;
            font-size: 175%;
        }

        .body {
            color: #efefef;
            font-size: rem-calc(16);
            font-family: 'Lato', sans-serif;
            font-weight: 400;
        }
    }

    &.sec2 {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $black;
        align-content: space-around;

        .phonecellcontrol {
            text-align: right;

            @media print {
                display: none;
            }
        }

        .phone {
            font-family: $header-font-family;
            color: $white;
            font-size: rem-calc(20);
        }

        .phonetxt {
            opacity: 0.8;
            font-size: 90%;
            font-family: 'Lato', sans-serif;
        }

        .body {
            opacity: 0.8;
            font-size: 90%;
            font-family: 'Lato', sans-serif;

            @media print {
                font-size: rem-calc(12);
                position: fixed;
                bottom: 0;
            }
        }
    }
}

