@import "../settings";
.card {
    padding: 40px;

    @media print {
        /*display: none;*/
        border: solid 1px;
    }

    .card-name {
        text-align: center;
        align-content: center;
        font-family: Georgia, 'Times New Roman';
        font-size: rem-calc(28);
        color: #002330;
    }

    .card-body {
        text-align: center;
        align-content: center;

        input {
            font-family: Lato, sans-serif;
            font-weight: 400;
            color: #8e9698;
            font-size: rem-calc(18);
        }
    }

    .cardinputbox {
        text-align: center;
        align-content: center;
    }
}
.card-btn {
    font-family: Lato, sans-serif;
    font-weight: 400;
    color: #ffffff;
    font-size: rem-calc(18);
    margin:5px, 4px;
}