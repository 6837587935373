﻿// Text module
.text {
    @include module-padding();
    
    text-align: center;

    p {
        font-size: rem-calc(20);
        font-weight: $global-weight-bold;

        &:last-child {
            margin-bottom: 0;
        }

        > .button:first-child {
            margin-top: rem-calc(20);
            margin-bottom: 0;

            @include breakpoint(medium){
                margin-top: rem-calc(50);
            }
        }
    }
}
.memberlogo {
    text-align: center;
}
.UnderNavigationLine {
    border-bottom:1px solid; 
    border-bottom-color: #d9d9d9;
}
.allnavlinks {
    margin-top:48px;
}