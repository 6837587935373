@import "../settings";

.article {
    margin-bottom: 50px;
}
.article_headerlbl {
    font-family:Lato, sans-serif;
    color: #8e9698;
    font-weight:bold;
    font-size: rem-calc(16);
}
.article_type {
    font-family: 'Lato regular',sans-serif;
    font-size: rem-calc(18);
    color: #8e9698;
}
.article_date {
    color: #8e9698;
    font-family: 'Lato regular',sans-serif;
    font-size: rem-calc(16);
}
.article_bodytext {
    font-size: rem-calc(18);
    font-family: 'Lato regular',sans-serif;
    
}
.article_headertext {
    font-size: rem-calc(28);
    font-family: 'Georgia regular', 'Times New Roman', Times, serif;
}

.article_newsitemarrow {
    padding-top: 30px;
    text-align: center;
}

.headerlbl {
    color: #979ea0;
    margin-bottom: 30px;
}
