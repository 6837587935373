@import "../settings";

.memberslide-wrapper {
    overflow: hidden;
    background-color: #ececea;
    @media print {
        display: none;
    }
}

.memberslide {
    background-color: #ececea;
    padding-top: 25px;
    padding-bottom: 40px;
    align-content:center;
}
.MemberSlideHeader {
    color: #a8aeaf;
    font-family: 'Lato', sans-serif;
    padding-top:25px;
}
.memberslide__item {
    position: relative;
    overflow: hidden;
    height: rem-calc(80);
    display: none;
    picture:hover {
        img:first-child {
            display:none;
        }
    }
}
.memberslide__image {
    position: relative !important;
    max-width: none;
    @include horizontal-center;
}
.slick-next-member, .slick-prev-member {
    position: absolute;
    top: 50%;
    right: -75px;
    margin-top: -30px;
    cursor: pointer;
    width: 50px;
    height: 60px;
    border: none;
    z-index: 5;

    @include breakpoint(small only) {
        display: none !important;
    }
}

.slick-next-member {
    background: url('../../public/imgs/heroarrowright.png') no-repeat;
}

.slick-prev-member {
    background: url('../../public/imgs/heroarrowleft.png') no-repeat;
    right: auto;
    left: -75px;

    @media (max-width: 1440px) {
        display: none !important;
    }
}