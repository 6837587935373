/*@font-face {
  font-family: 'icomoon';
  src:  url('/public/fonts/icomoon.eot?tpdl7x');
  src:  url('/public/fonts/icomoon.eot?tpdl7x#iefix') format('embedded-opentype'),
    url('/public/fonts/icomoon.ttf?tpdl7x') format('truetype'),
    url('/public/fonts/icomoon.woff?tpdl7x') format('woff'),
    url('/public/fonts/icomoon.svg?tpdl7x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

//@import "../../assets/fonts/src/style";