﻿$marginSoftValue: 25px;
$GeogieFontFamily: Georgia, 'Times New Roman', Times, serif;
$NivSizeFont_1: 112%;
$NivSizeFont_2: 150%;
$NivSizeFont_4: 250%;

.FilterBackground {
    background-color: #ececea;
    padding-top: 50px;
    padding-bottom: 50px;
}
.searchmainheader {
    text-align: center;
    font-size: $NivSizeFont_4;
    font-family: $GeogieFontFamily;
    margin-top: $marginSoftValue;
    margin-bottom: $marginSoftValue;
}
.searchmainbody {
    margin-bottom: $marginSoftValue;
    font-size: $NivSizeFont_1;
    font-family: 'Lato', sans-serif;
}
.searchinfotypes {
    font-size: $NivSizeFont_2;
    font-family: $GeogieFontFamily;
    margin-bottom: $marginSoftValue;
}
.searchpublishdate {

}
