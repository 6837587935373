.directlink {
    visibility: hidden;
    pointer-events: none;
    position: relative;
    color: $white;
    text-align: center;
}

.directlink__entrance {
    position: absolute;
    width: 90%;
    right: 5%;
    top: rem-calc(63);
    z-index: 0;
    padding: rem-calc(45 20 0);
    background-color: $red;

    @include breakpoint(medium) {
        padding: rem-calc(50 20 20);
        right: rem-calc(15);
        top: rem-calc(150);
        width: rem-calc(350);
        height: auto;
    }
}

.directlink__close {
    position: absolute;
    right: rem-calc(15);
    top: rem-calc(10);
    text-decoration: underline;
    color: $white;

    &:hover {
        color: $white;
    }
}

.directlink--active {
    visibility: visible;
    pointer-events: all;
    z-index: 9;
}
